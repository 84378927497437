@font-face {
    font-family: "RobotoMono";
    src: url("/public/assets/fonts/RobotoMono.ttf") format("truetype");
}
@font-face {
    font-family: "RubikMonoOne-Regular";
    src: url("/public/assets/fonts/RubikMonoOne-Regular.ttf") format("woff");
}

.main-font{
    font-family: "RubikMonoOne-Regular";
}

.secondary-font{
    font-family: "RobotoMono";
}

*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	font-size: 18px;
	--color-text: #fff;
	--color-text-alt: #767474;
	--color-bg: #000000;
	--color-link: #aaa;
	--color-link-hover: #fff;
}

html {
    scroll-behavior: auto !important;
	-webkit-overflow-scrolling: touch !important;
}

::-webkit-scrollbar {
    display: none;
}

html, body{
	width: 100%;
	min-height: 100% !important;
	overflow: scroll;
	
}

body {
	margin: 0;
	color: var(--color-text);
	background-color: var(--color-bg);
	font-family: botanika-mono-web, monospace;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

header{
	position: fixed;
	display: flex;
	align-items: center;
	width: 100%;
	height: 5%;
	z-index: 999;
}

header > nav{
	width: 100%;
}

.clock{
    font-size: 0.75em;
    text-transform: uppercase;
    font-weight: 600;
}

.button-container{
	width: 50%;
	display: flex;
	align-items: center;
    justify-content: start;
	text-align: center;
}

.button-container > button{
	font-family: "RobotoMono";
	font-size: 0.75em;
	font-weight: 600;
    text-transform: uppercase;
    text-align: center;
	background-color: transparent;
    border: none;
	height: 100%;
	margin: 0;
	color: #ffffff;
}
.button-container > a{
    display: flex;
    align-items: center;
    justify-content: center;
	font-family: "RobotoMono";
	font-size: 0.75em;
	font-weight: 600;
    text-transform: uppercase;
    text-align: center;
	background-color: transparent;
    border: none;
	height: 100%;
	margin: 0;
	color: #ffffff;
    padding: 1px 6px;
}

.content-open {
	overflow: hidden;
}

.oh {
    position: relative;
    overflow: hidden
}

.oh__inner {
    will-change: transform;
    display: inline-block;
}

.line {
	transform-origin: 0;
	will-change: transform;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
	content: '';
	position: fixed;
	z-index: 1000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5,0.5,1);
	}
}

a {
	text-decoration: none;
	color: var(--color-link);
	outline: none;
	cursor: pointer;
}

a:hover {
	color: var(--color-link-hover);
	outline: none;
}

/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
	/* Provide a fallback style for browsers
	 that don't support :focus-visible */
	outline: none;
	background: lightgrey;
}

a:focus:not(:focus-visible) {
	/* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
	background: transparent;
}

a:focus-visible {
	/* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
	outline: 2px solid red;
	background: transparent;
}

.unbutton {
	background: none;
	border: 0;
	padding: 0;
	margin: 0;
	font: inherit;
	fill: #ffffff;
	cursor: pointer;
	color: inherit;
	display: flex;
	align-items: center;
}

.unbutton:focus {
	outline: none;
}

.unbutton span:first-child {
	margin-right: 1rem;
}

.unbutton span:last-child {
	margin-left: 1rem;
}

main {
	position: relative;
	display: grid;
	grid-template-areas: 'frame' 'page';
}

.frame {
	grid-area: frame;
	padding: 1.5rem 2rem;
	z-index: 1000;
	background: var(--color-bg);
	display: grid;
	grid-template-columns: auto auto;
	grid-template-rows: auto;
	grid-template-areas: 'title' 'prev' 'sponsor';
	align-content: space-between;
	text-transform: uppercase;
	grid-row-gap: 5vh;
}

.frame__title {
	grid-area: title;
	display: flex;
}

.frame__title-main {
	font-size: 1rem;
	margin: 0;
	font-weight: normal;
	line-height: 1;
	align-self: center;
	text-align: right;
}

.frame__title-back {
	position: relative;
	display: flex;
	align-items: flex-end;
	margin-bottom: 0.15rem;
}

.frame__title-back span {
	display: none;
}

.frame__title-back svg {
	fill: currentColor;
}

.frame__prev {
	grid-area: prev;
}

.preview-wrap {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 3.5em;
    grid-row-gap: 3.5em;
	grid-area: page;
	padding: 0 2rem 3rem;
	position: relative;
	z-index: 100;
	margin-top: 10%;
}

.preview-wrap > .projects{
	font-family: "RubikMonoOne-Regular";
	font-weight: 600;
	position: absolute;
	top: 0;
	padding: 0 2rem 3rem;

}

.item1{
	grid-area: 1 / 1 / 1 / 8;
}
.item2{
	grid-area: 2 / 1 / 2 / 4;
}
.item3{
	grid-area: 2 / 4 / 2 / 8;
}

.content-open .preview-wrap {
	pointer-events: none;
}

.preview {
	width: 85%;
	min-height: 400px;
	padding: 3rem 1rem 0;
	margin: 0 auto;
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	will-change: transform;
}

.preview__img-wrap {
	grid-area: 1 / 1 / -1 / 2;
	cursor: pointer;
}

.preview__img {
	width: 100%;
	height: 650px;
	overflow: hidden;
	position: relative;
}

.preview__img-inner {
	width: 100%;
	height: 100%;
	/* background-size: auto; */
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-color: #000000;
	transform-origin: 0 !important;
	transform: none !important;
}

.content__meta {
	margin-bottom: 2rem;
	color: var(--color-text-alt);
}

.preview__title {
	text-align: center;
	align-self: end;
	justify-self: start;
	grid-area: 1 / 3 / -1 / 4;
	position: relative;
	transform: translate3d(0,50%,0);
	pointer-events: none;
}

.preview__title-main {
	line-height: 1.2;
	margin: 0;
	font-weight: normal;
	font-size: clamp(1rem,4vw,2rem);
	font-family: "RobotoMono", sans-serif;
	font-weight: 300;
}

.preview__title-main > span {
	display: block;
}

.preview__title-main > span:last-child {
	display: block;
	padding: 0;
	font-family: "RobotoMono", sans-serif;
	font-weight: 500;
}

.preview__desc {
	margin: 0 auto;
	max-width: 400px;
	text-transform: uppercase;
	line-height: 1.2;
}

.content-wrap {
	position: -webkit-sticky; /* Safari */
	position: sticky;
	top: 0;
	grid-area: page;
	height: 100%;
	overflow: hidden;
	display: grid;
	grid-template-areas: 
	'back back'
	'content content';
	grid-template-rows: auto 1fr;
	pointer-events: none;
	padding: 2rem;
	overflow: hidden;
}

.action {
	visibility: hidden;
	pointer-events: none;
	opacity: 0;
}

.content-open .action {
	visibility: visible;
	pointer-events: auto;
}

.action svg {
	width: 2em;
	height: 2em;
}

.action--back {
	justify-self: start;
	grid-area: back;
	padding: 1rem 0;
}

.action span {
	text-transform: uppercase;
}

.content {
	grid-area: content;
	grid-row: 2 / span 2;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	pointer-events: none;
	opacity: 0;
	display: grid;
	grid-gap: 2vw;
	grid-template-columns: 100%;
	grid-template-areas:
	'img' 
	'thumbs'
	'text';
	grid-template-rows: 20vh min-content auto;
}

.content__images{
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: center;
	height: 100%;
	width: 100%;
	grid-row: 3 / 3;
	grid-column: 1 / 3;
	z-index: 99;
	padding: 5%;
	opacity: 1;
	transition: opacity 2s;
}

.content__images > img{
	width: 100%;
	height: 100%;
	margin: 2% 0;
}

.transparent{
	display: none;
	opacity: 0 !important;
}

.content--current {
	position: relative;
	pointer-events: auto;
	opacity: 1;
}

.content__group {
	grid-area: text;
}

.content__title {
	font-family: "RubikMonoOne-Regular", sans-serif;
	font-weight: 400;
	font-size: clamp(1rem,3.5vw,2.5rem);
}

.content__title span {
	display: block;
}

.content__title > span:last-child {
	transform: translateY(-25%);
	font-family: "RubikMonoOne-Regular", sans-serif;
	font-weight: 500;
	padding: 0 0 0 0.75rem;
}

.content .preview__img {
	grid-area: img;
}

.content__thumbs {
	grid-area: thumbs;
	display: grid;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    grid-gap: 2vw;
    justify-content: start;
}

.content__thumbs-item {
	width: 400px;
}

.content__thumbs-item:hover {
	cursor: not-allowed;
}

.hidden {
	opacity: 0;
	pointer-events: none;
}

@media screen and (min-width: 53em) {
	main {
		grid-template-areas: 'frame page';
		grid-template-columns: auto 1fr;
	}
	.frame {
		position: -webkit-sticky; /* Safari */
		position: sticky;
		top: 0;
		height: 100vh;
		opacity: 0.5;
		padding: 2rem;
	}

	.frame__title, 
	.frame__prev,
	#cdawrap {
		justify-self: start;
		-webkit-writing-mode: vertical-rl;
		writing-mode: vertical-rl;
		transform: rotate(180deg);
	}

	body #cdawrap {
		justify-self: start;
	}

	.frame__prev {
		margin: 1rem 0 0;
	}

	.frame__title-back {
		transform: scale3d(1,-1,1);
	}
	.preview__desc {
		margin: -2.5rem auto 0;
	}
	.content {
		grid-template-columns: 60% 1fr;
		grid-template-areas:
		'... ...' 
		'img text'
		'thumbs text'
		'... ...'
		'... ...';
		grid-template-rows: 2rem 1fr auto auto auto 1rem;
	}
	.content__thumbs-item {
		width: 100%;
	}
	.content__text {
		max-width: 400px;	
	}
}

.about-title-wrapper{
	overflow: hidden;
}

.about-desc-wrapper{
	overflow: hidden;
}

.about-wrapper{
    height: 100%;
    width: 100%;
    background-color: #000000;
    border-radius: 0.375rem;
    padding: 4%;
}

.project-col{
    margin: 1%;
    width: fit-content;
}

.details-col{
    border-radius: 0.375rem;
 
}


.proj-title{
    font-family: "RubikMonoOne-Regular";
    font-weight: 400;
    letter-spacing: -1px;
    margin: 0 !important;
    font-size: 3em;
    text-transform: uppercase;
}

.proj-desc{
    font-family: "Hanken Grotesk", sans-serif;
    font-weight: 200;
    font-size: 1.7em;
    letter-spacing: 0px;
    margin: 0 !important;
}

.proj-link{
    right: 5%;
    bottom: 2%;
    background-color: #000000;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    padding: 2%;
    font-family: "Brandford-Regular";
    color: #ffffff;
    text-decoration: none;
}



.email{
    display: flex;
    flex-direction: column;
    
}

.email-me{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 75%;
    border-radius: 0.375rem;
    padding: 2%;
    background-color: #000000;
    text-decoration: none;
    color: #ffffff;
}
.email-me:hover{
    text-decoration: underline;
}

.color-rating{
    font-family: "RobotoMono";
    font-size: 0.95em;
 
 
 
}

.beginner{
	font-family: "RobotoMono";
    background-color: #86ff71;
	color: #000000;
}
.intermediate{
	font-family: "RobotoMono";
    background-color: #fff156;
	color: #000000;
}
.expert{
	font-family: "RobotoMono";
    background-color: #fa5036;
	color: #000000;
}

.spacer{
    height: 100%;
    width: 20px;



}

.square{
    height: 10px;
    width: 10px;
    border: 1px solid black;


}

.stack-wrapper{
	border-bottom: 2px solid transparent;
}

.stack-wrapper:last-child{
    border: none;
}


.stack-wrapper svg{
    width: 20px;
    height: 20px;
}


.stack-label-wrapper{
	display: flex;
	align-items: center;
	justify-content: start;
    width: 240px;
	overflow: hidden;
}

.stack-label{
    width: 100%;
    font-family: "RubikMonoOne-Regular";
    font-size: 1em;
    margin-right: 5%;
    margin-bottom: 2%;
}

.stack-item-wrapper{
    height: fit-content;
    width: 100%;
	overflow: hidden;
}

.stack-item-wrapper ul{
    font-family: "Hanken Grotesk", sans-serif;

    width: 100%;
}

.stack-item-wrapper span{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1% 2%;
    border: 1px solid black;
    border-radius: 24px;
    height: fit-content;
    min-width: 0;
    text-align: center;
    margin-right: 1%;
    margin-top: 1%;
    margin-bottom: 1%;
}

.socials-wrapper{
    width: 175px;
    top: 24px;
    right: 24px;
}

.socials-list{
    margin: 0;
    padding: 0;
}

.socials-list svg{
    width: 48px;
    height: 48px;
	fill: #ffffff;
}



.exp-parent {
	display: grid;
	grid-template-columns: 200px 700px;
	grid-template-rows: repeat(9, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	width: fit-content;
	height: fit-content;
	}
	
.exp-div1 { grid-area: 1 / 1 / 2 / 2; }
.exp-div2 { grid-area: 2 / 1 / 3 / 2; }
.exp-div3 { grid-area: 3 / 1 / 4 / 2; }
.exp-div4 { grid-area: 4 / 1 / 5 / 2; }
.exp-div5 { grid-area: 5 / 1 / 6 / 2; }
.exp-div6 { grid-area: 6 / 1 / 7 / 2; }
.exp-div7 { grid-area: 7 / 1 / 8 / 2; }
.exp-div8 { grid-area: 8 / 1 / 9 / 2; }
.exp-div9 { grid-area: 9 / 1 / 10 / 2; }
.exp-div10 { grid-area: 1 / 2 / 2 / 3; }
.exp-div11 { grid-area: 2 / 2 / 3 / 3; }
.exp-div12 { grid-area: 3 / 2 / 4 / 3; }
.exp-div13 { grid-area: 4 / 2 / 5 / 3; }
.exp-div14 { grid-area: 5 / 2 / 6 / 3; }
.exp-div15 { grid-area: 6 / 2 / 7 / 3; }
.exp-div16 { grid-area: 7 / 2 / 8 / 3; }
.exp-div17 { grid-area: 8 / 2 / 9 / 3; }
.exp-div18 { grid-area: 9 / 2 / 10 / 3; }

.exp{
	display: grid;
	grid-template-rows: 150px;
	/* display: flex; */
	/* align-items: start; */
	/* justify-content: center; */
}

.column-spacer{
	grid-row: 5 / 5 !important;
}














@media all and (min-width: 1024px) and (max-width: 1280px) {
	.preview{
		width: 100%;
	}
 }

@media all and (min-width: 768px) and (max-width: 1024px) {
	.proj-details-wrapper{
		flex-direction: column !important;
	}

	.about-desc-wrapper{
		width: 100% !important;
		margin: 5% 0 10% 0;
	}

	.skills-wrapper{
		width: 100% !important;
		margin: 0 !important;
	}
	.about-title{
		font-size: 2.5em;
		align-items: start !important;
		text-align: start;
	}
 }

@media all and (min-width: 480px) and (max-width: 768px) {
	.content{
		grid-template-rows: repeat(1, 1fr);
	}
	.content__title > span:last-child{
		padding: 0;
	}
	.content__group{
		grid-row: 6 / 6;
	}
	.content__images{
		grid-row: 10 / 10;
	}
	.preview-wrap{
		display: flex;
		flex-direction: column;
		padding: 0;
	}
	.preview{
		width: 100vw;
		height: 50%;
		padding: 10%;
	}





	.proj-details-wrapper{
		flex-direction: column !important;
	}

	.about-desc-wrapper{
		width: 100% !important;
		margin: 5% 0 10% 0;
	}

	.skills-wrapper{
		width: 100% !important;
		margin: 0 !important;
	}
	.about-title{
		font-size: 2em;
		align-items: start !important;
		text-align: start;
	}
	.socials-list svg{
		width: 32px !important;
		height: 32px !important;
	}
	.stack-item-wrapper span{
		font-size: 0.85em;
	}
 }

@media all and (max-width: 480px) {
	.content{
		grid-template-rows: repeat(1, 1fr);
	}
	.content__title > span:last-child{
		padding: 0;
	}
	.content__group{
		grid-row: 6 / 6;
	}
	.content__images{
		grid-row: 10 / 10;
	}
	.preview-wrap{
		display: flex;
		flex-direction: column;
		padding: 0;
		margin-top: 4rem;
	}
	.preview{
		width: 100vw;
		padding: 10%;
	}
	.preview__img{
		height: 350px;
	}



	.about-wrapper{
		padding-top: 10% !important;
	}
	.proj-details-wrapper{
		flex-direction: column !important;
	}
	.about-title-wrapper{
		flex-direction: column !important;
	}
	.about-title-wrapper > .socials-wrapper{
		margin-top: 5% !important;
	}
	.about-desc-wrapper{
		width: 100% !important;
		margin: 5% 0 10% 0;
	}
	.about-desc{
		font-size: 1.25em !important;
	}
	.skills-wrapper{
		width: 100% !important;
		margin: 0 !important;
	}
	.about-title{
		font-size: 1.25em;
		align-items: start !important;
		text-align: start;
	}
	.socials-list svg{
		width: 24px !important;
		height: 24px !important;
	}
	.stack-item-wrapper span{
		font-size: 0.65em;
	}
	.stack-label{
		font-size: 0.85em;
	}
	.stack-wrapper svg{
		width: 10px;
		height: 10px;
	}
 }


/* Portrait */
@media screen and (orientation:portrait) { /* Portrait styles here */ }
/* Landscape */
@media screen and (orientation:landscape) { /* Landscape styles here */ }


/* CSS for iPhone, iPad, and Retina Displays */

/* Non-Retina */
@media screen and (-webkit-max-device-pixel-ratio: 1) {
}

/* Retina */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-device-pixel-ratio: 1.5) {
}

/* iPhone Portrait */
@media screen and (max-device-width: 480px) and (orientation:portrait) {
} 

/* iPhone Landscape */
@media screen and (max-device-width: 480px) and (orientation:landscape) {
}

/* iPad Portrait */
@media screen and (min-device-width: 481px) and (orientation:portrait) {
}

/* iPad Landscape */
@media screen and (min-device-width: 481px) and (orientation:landscape) {
}